const firebaseConfig = {
  projectId: "dolce-vita-ba68e",
  appId: "1:910549292236:web:93d857b99f397c49a11e69",
  databaseURL:
    "https://dolce-vita-ba68e-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "dolce-vita-ba68e.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyAbFPYGwOgM-n2-NkU8xGoCcNjiAciHQjA",
  authDomain: "dolce-vita-ba68e.firebaseapp.com",
  messagingSenderId: "910549292236",
  measurementId: "G-MZ9TGMCDMM",
};

export default firebaseConfig;
